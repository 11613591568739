import React, { PropsWithChildren, useMemo } from 'react';
import { InstantSearch } from 'react-instantsearch';
import TypesenseInstantSearchAdapter, {
  BaseSearchParameters,
} from 'typesense-instantsearch-adapter';
import { APP_CONFIG } from '../shared/config';

interface TypesenseProviderProps {
  indexName: string;
  additionalSearchParameters: BaseSearchParameters;
  children: React.ReactNode;
}

let searchHost = APP_CONFIG.Search.BaseUrl ?? 'localhost';
let searchPort = APP_CONFIG.Search.Port ?? 8108;
let searchProtocol = APP_CONFIG.Search.Protocol ?? 'http';
let searchPath = '';
const apiKey = APP_CONFIG.Search.ApiKey;

// check if the search host is a URL, in which case we should extract its components
if (/https?:\/\//.exec(searchHost)) {
  const searchUrl = new URL(searchHost);
  searchHost = searchUrl.host;
  searchPort = Number(searchUrl.port || searchPort);
  searchProtocol = searchUrl.protocol.replace(':', '');
  searchPath = searchUrl.pathname;
}

export const TypeSenseProvider: React.FC<TypesenseProviderProps> = ({
  children,
  indexName,
  additionalSearchParameters,
}) => {
  const searchClient = useMemo(() => {
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: apiKey, // Be sure to use an API key that only allows search operations
        nodes: [
          {
            host: searchHost,
            port: searchPort,
            protocol: searchProtocol,
            path: searchPath,
          },
        ],
        cacheSearchResultsForSeconds: 2 * 60,
      },

      additionalSearchParameters: additionalSearchParameters,
    });
    return typesenseInstantsearchAdapter.searchClient;
  }, [additionalSearchParameters]);
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      future={{ preserveSharedStateOnUnmount: true }}
    >
      {children}
    </InstantSearch>
  );
};

const agenciesSearchParams = {
  query_by: 'displayName,information,description',
  sort_by: 'displayName:asc',
  pinned_hits: APP_CONFIG.Search.PinnedAgencies,
  filter_curated_hits: 1,
};

export const AgencySearchProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <TypeSenseProvider indexName="Agencies" additionalSearchParameters={agenciesSearchParams}>
    {children}
  </TypeSenseProvider>
);

const globalSearchParams = {
  query_by: 'title,content,description',
};

export const GlobalSearchProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <TypeSenseProvider indexName="Content" additionalSearchParameters={globalSearchParams}>
    {children}
  </TypeSenseProvider>
);
